<template>
  <div style="position: relative;">
    <div class="top_btns">
      <div class="left_box">
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px -26px;width:400px;height:138px" label-width="120px" class="styleForm">
            <el-form-item label="存货名称 ：">          
              <el-input v-model="listQuery.name" placeholder="请输入名称" style="width: 200px;" size="small" clearable/>
            </el-form-item>
            <el-form-item label="存货为 0 ："> 
              <el-radio v-model="listQuery.not0" :label="true">不显示</el-radio>
              <el-radio v-model="listQuery.not0" :label="false">显示</el-radio>
            </el-form-item>
            
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <qzf-button jz="true" @success="inventoryStockIn" size="small" :disabled="!$buttonStatus('ch_chcsh_cshrk')" v-if="this.startPeriod == this.period">
          <el-icon><SortDown /></el-icon><span  > 初始化入库</span>
        </qzf-button>

        <el-dropdown size="small" style="margin-left:10px">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="b" @click="daoRu" v-if="$buttonStatus('ch_chcsh_dr')">
                <span>导入</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" @click="initDaoru" v-if="$buttonStatus('ch_chcsh_cjdr')">
                <span>采集初始化导入</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button :disabled="!$buttonStatus('ch_chcsh_cz')" jz="true" size="small" :loading="resetLoading" @success="resetInventory" style="margin-left:10px">重置</qzf-button>
        <el-button type="primary" plain @click="daochu" size="small" icon="Folder">导出</el-button>
      </div>
    </div>
    <div :style="{ height: this.contentStyleObj }">
      <vxe-table
        ref="vxeTableInstance"
        v-loading="loading"
        :data="tableData"
        :scroll-y="{enabled: true, gt: 0,}"
        height="auto"
        style="width: 100%;"
        border
        stripe
        auto-resize
        size="mini"
        :show-footer="!!tableData.length"
        :footer-data="getFooterDataTable"
        @sort-change="sortChange"
        @checkbox-all="handleSelectionAllChange"
        @checkbox-change="handleSelectionChange"
        :column-config="{resizable: true}"
      >
    <!-- <el-table stripe size="small" border show-summary :summary-method="getSummaries" :height="contentStyleObj" :data="list" v-loading="loading" ref="tableScrollPeriod" id="tableLazyLoadInit"> -->
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column align="center" field="name" title="科目名称" min-width="80" />
        <vxe-column field="inventoryName" title="存货名称" min-width="80" />
        <vxe-column align="center" field="spec" title="规格型号" min-width="80" />
        <vxe-column align="center" field="unit" title="计量单位" min-width="80" />
        <vxe-column align="center" field="count" title="期初数量" min-width="80" />
        <vxe-column align="center" field="amount" title="期初金额" min-width="80" />
      </vxe-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
  <inventoryStock @success="getList()" ref="inventoryStockIn" name="初始化入库"></inventoryStock>
  <qzf-import-back @success="getList()" ref="importBck" from="存货" :hideButton="true"></qzf-import-back>


  <!-- 采集的提示 -->
  <el-dialog
    title="采集初始化导入"
    v-model="dialogVisible"
    width="30%"
    :before-close="handleClose"
    class="dialogVisible"
  >
    <div style="font-size: 15px;"><i class="iconfont icon-gantanhao"></i>
      确认将已采集的存货进行初始化?</div>
    <div style="color: red;">提示：本操作会覆盖原初始化数据</div>
    <el-radio v-model="type" :label="0">按照采集科目余额表</el-radio>
    <el-radio v-model="type" :label="1">按照采集存货</el-radio>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="makeSureImport" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { wbVoucherFxOther} from "@/api/export";
import {  importList ,delInventoryDetailInit ,importInventoryFromSubject} from "@/api/inventory"
import inventoryStock from "./inventoryStock.vue"
export default {
  name:'inventoryInit',
  data() {
    return {
      list:[],
      listQuery:{
        page:1,
        limit:20,
        name:'',
        not0:false
      },
      total:0,
      loading:false,
      startPeriod:this.$store.getters['user/comInfo'].startPeriod,
      period:this.$store.getters['user/comInfo'].period,
      dialogVisible:false,
      type:0,
      resetLoading:false,
      totalPage:0,
      pageSize:35,
      tableData:[],
      currentPage:1,
    }
  },
  components:{
    inventoryStock,
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(338)
  },
  methods:{
    inventoryStockIn(){
      this.$refs.inventoryStockIn.init()
    },
    getFooterDataTable({ columns, data }) {
      const sumKey = ['amount', 'count',]
      const footerData = columns.map((item, i) => {
        if (i === 0) {
          return '合计'
        }
        if (sumKey.includes(item.field)) {
          return data.reduce((acc, d) => {
            acc = +((acc + (d[item.field] || 0)).toFixed(2))
            return acc
          }, 0)
        }
        return ''
      })
      return [footerData]
    },
    getList(){
      importList(this.listQuery).then(res=>{
        if(res.data.msg == "success"){
          this.tableData = res.data.data.list || []
          this.total = res.data.data.total
        }
      })
    },
    
    resetInventory(){
      this.$confirm('确定要重置吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.resetLoading = true
        delInventoryDetailInit({}).then(res => {
          this.resetLoading = false
          if(res.data.msg == "success"){
            this.$qzfMessage('重置成功')
            this.getList()
          }
        })
      })
    },
    daoRu(){
      this.$refs.importBck.handImport()
    },
    initDaoru(){
      this.dialogVisible = true
      // const h = this.$createElement;
      // 本操作会覆盖原初始化数据
      // this.$confirm('采集初始化导入', {
      //   title: '采集初始化导入',
      //   message: h('div', [h('p', '确认将已采集的存货进行初始化?'), h('p',{style:'color:red;margin-top:15px;position:absolute;top:6px;font-size:12px'}, '提示：本操作会覆盖原初始化数据')]),
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => {
      //   this.loading = true
      //   importInventoryFromSubject({}).then(res => {
      //     this.loading = false
      //     if(res.data.msg == "success"){
      //       this.$qzfMessage('导入成功')
      //       this.getList()
      //     }
      //   })
      // })
    },
    makeSureImport(){
      this.loading = true
      importInventoryFromSubject({type:this.type}).then(res => {
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage('导入成功')
          this.getList()
        }
      })
    },
    // 导出
    daochu(){
      let param = {
        exportType:"inventory_import",
        outType:'excel',
        period:this.period
      }
      wbVoucherFxOther(param).then(res => {
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery = {
        page:1,
        limit:originLimit,
        name:'',
        not0:false
      }
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 275px);
  border:1px solid #e6e6e6;
  position: relative;
}
.top_btns {
  // margin-bottom: 36px;
  //  .left_box{
  //   float: left;
  //   width: 30%;
  // }
  // .right_box {
  //   float: right;
  // }

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

</style>

<style lang="scss">
.dialogVisible {
  .el-dialog__body{
    padding-bottom: 0px;
    padding-top: 10px;
  }
}
</style>