<template>
  <div class="zangu-container button_bg">
    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      title="手动出入库"
      v-model="dialogVisible"
      width="1350px"
    >
      <h1 class="title">手动出入库</h1>
      <div class="header">
        <div>
          <span>日期：</span>
          <el-date-picker
            v-model="info.inventoryDate"
            :picker-options="pickerBeginDateBefore"
            type="date"
            placeholder="选择日期"
            size="small"
          ></el-date-picker>
        </div>
      </div>
      <table cellspacing="0">
        <thead>
          <tr>
            <th class="bor_top">出库存货</th>
            <th class="bor_top">入库存货</th>
            <th class="bor_top">数量</th>
            <th class="bor_top">单价</th>
            <th class="bor_top">
              <div>
                <strong class="tit">金额</strong>
                <div class="money_unit">
                  <span>亿</span>
                  <span>千</span>
                  <span>百</span>
                  <span>十</span>
                  <span>万</span>
                  <span>千</span>
                  <span>百</span>
                  <span>十</span>
                  <span>元</span>
                  <span>角</span>
                  <span>分</span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style="position: relative">
            <td>
              <inventory-list
                :borderNone="true"
                v-model:inventoryId="info.inventoryIdOut"
                :type="false"
              ></inventory-list>
            </td>
            <td>
              <inventory-list
                :borderNone="true"
                v-model:inventoryId="info.inventoryIdIn"
                :type="false"
              ></inventory-list>
            </td>
            <td>
              <input
                @blur="inputCountBlur(info.count)"
                type="text"
                v-model.number="info.count"
                @change="handleCountInput(info)"
                size="small"
              />
            </td>
            <td>
              <input
                @blur="inputCountBlur(info.danjia)"
                type="text"
                v-model.number="info.danjia"
                @change="handleDanjiaInput(info)"
                size="small"
              />
            </td>
            <td class="td-money">
              <div v-if="!info.edit" @click="handleMoney(info)">
                <span v-if="info.amount">
                  {{ (Number(info.amount).toFixed(2) * 100).toFixed(0) }}
                </span>
              </div>
              <input
                v-else
                @blur="inputBlur(info)"
                type="text"
                v-model.number="info.amount"
                size="small"
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <td colspan="4" style="padding: 10px">合计：</td>
          <td class="td-money">
            <div>
              <span>
                {{ info.amount }}
              </span>
            </div>
          </td>
        </tfoot>
      </table>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取消</el-button
          >
          <el-button size="small" type="primary" @click="handleSaveshougong"
            >确认</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { inventoryAdjustment } from "@/api/inventory";
import { getPeriodTime } from "@/utils/fun";
export default {
  name: "manulStock",
  data() {
    return {
      dialogVisible: false,
      info: {
        inventoryDate: getPeriodTime(), //开票日期
        amount: 0,
        count: 0,
        inventoryIdOut: 0,
        inventoryIdIn: 0,
      },
    };
  },
  methods: {
    init() {
      this.dialogVisible = true;
    },
    handleMoney(item) {
      item.edit = true;
    },
    inputBlur(item) {
      item.edit = false;
      if (isNaN(item.amount)) {
        item.amount = 0;
        this.$qzfMessage("请输入合法金额数", 2);
        return;
      }
      if (item.count != "") {
        item.danjia = (Number(item.amount) / Number(item.count)).toFixed(2);
      }
    },
    inputCountBlur(item) {
      if (isNaN(item)) {
        item = 0;
        this.$qzfMessage("请输入合法数", 2);
        return;
      }
    },
    // 入库数量输入框
    handleCountInput(item) {
      if (item.danjia) {
        item.amount = Number(
          (Number(item.danjia) * Number(item.count)).toFixed(2)
        );
      }
    },
    // 入库输入单价
    handleDanjiaInput(item) {
      if (isNaN(item.amount)) {
        item.amount = 0;
        this.$qzfMessage("请输入合法数", 2);
        return;
      }
      if (item.count == "") {
        item.amount = Number(Number(item.danjia) * (Number(item.count) + 1));
      } else {
        item.amount = Number(Number(item.danjia) * Number(item.count));
      }
    },
    //入库输入金额
    handleMoney(item) {
      item.edit = true;
    },
    handleSaveshougong() {
      // if (this.info.count == 0) {
      //   this.$qzfMessage("数量不可为0", 2);
      //   return;
      // }
      if (!this.info.inventoryIdIn && !this.info.inventoryIdOut) {
        this.$qzfMessage("请选择货品", 2);
        return;
      }
      inventoryAdjustment(this.info).then(res=>{
        if(res.data.msg == "success"){
            this.dialogVisible = false
            this.$qzfMessage("操作成功")
            this.$bus.emit('inventoryPeriodUpdate')
          }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.zangu-container {
  .title {
    text-align: center;
    font-size: 22px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
  }
  .bor_top {
    width: 20%;
    border-top: 1px solid #c2c2c2;
  }
  table {
    width: 100%;
    text-align: center;
    td,
    th {
      border-right: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;
    }
    th:nth-child(1),
    td:nth-child(1) {
      border-left: 1px solid #c2c2c2;
    }

    .tit {
      height: 25px;
      line-height: 25px;
      display: block;
    }
    .money_unit {
      text-align: center;
      line-height: 22px;
      height: 22px;
      font-size: 14px;
      border-top: 1px solid #dadada;
      display: flex;
      span {
        height: 100%;
        flex: 1;
        width: 18px;
      }
    }
    .td-money {
      width: 15%;
      text-align: center;
      background-image: url("http://etax.qizhangfang.net/template/eaccount/line1.png");
      background-repeat: repeat-y;
      width: 220px;
      input {
        border: 1px solid #f15a24;
        width: 100%;
        height: 45px;
      }
      > div {
        width: 100%;
        text-align: right;
        letter-spacing: 15px;
        position: relative;
        right: -6px;
        height: 100%;
        line-height: 45px;
        font-size: 14px;
        span {
          height: 100%;
          flex: 1;
          width: 18px;
        }
      }
    }
    tbody {
      tr {
        td {
          width: 150px;
          height: 45px;
        }
      }
    }
  }
}
</style>
