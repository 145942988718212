<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="成本调差"
    width="90%"
  >
  <div class="top_select">
    <subject-list v-model:subjectId="subjectId" :codes="$findCode(code)" :disabledIn="disabled" v-model:fzhsItemId="fzhsId"></subject-list>
  </div>
    <el-table :data="list" border :height="contentStyleObj" @selection-change="handleSelectionChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
    <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column label="名称" min-width="150" show-overflow-tooltip>
        <template #default="scope">
          <div>{{ scope.row.inventoryName }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="型号"
        min-width="90"
        align="center"
      >
        <template #default="scope">
          <div>{{ scope.row.inventorySpec }}</div>
        </template>
      </el-table-column>
      <el-table-column label="单位" min-width="90" align="center">
        <template #default="scope">
          <div>{{ scope.row.inventoryUnit }}</div>
        </template>
      </el-table-column>
      <el-table-column label="期末数量" min-width="90" align="center">
        <template #default="scope">
          <div>{{ scope.row.endCount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="期末金额" min-width="90" align="center">
        <template #default="scope">
          <div>{{ scope.row.endAmount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="单据类型" min-width="150" align="center">
        <template #default="scope">
          <el-radio-group v-model="scope.row.type">
            <el-radio :label="1">成本调差入库</el-radio>
            <el-radio :label="2">成本调差出库</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="成本调差金额" min-width="120" align="center">
        <template #default="scope">
          <el-input size="small" style="width: 90%" v-model="scope.row.amount"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="save()"
          plain
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {inventoryTclist,inventoryTc} from '@/api/inventory'
export default {
  name: "costAdjust",
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible:false,
      list:[],
      code: ["code5401"],
      disabled: false,
      contentStyleObj:{},
      subjectId:null,
      fzhsId:null,
      sels:[]
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(350)
  },
  methods: {
    init() {
      this.dialogFormVisible = true
      this.disabled = false;
      inventoryTclist({limit:0}).then(res=>{
        if(res.data.msg == 'success'){
          this.list = res.data.data ? res.data.data : []
          this.list.map(v=>{
            v.type = 1
          })
        }
      })
    },
    handleSelectionChange(e){
      this.sels = e
    },
    save(){
      if(!this.subjectId){
        this.$qzfMessage("请选择科目", 1);
        return
      }
      // if(this.sels.length == 0){
      //   this.$qzfMessage("至少选择一条", 1);
      //   return
      // }
      this.list.map(v=>{
        v.amount = Number(v.amount)
      })
      let param = {
        items:this.list,
        subjectId:this.subjectId,
        fzhsId:this.fzhsId
      }
      inventoryTc(param).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('保存成功');
          this.dialogFormVisible = false
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
</style>
