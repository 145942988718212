<template>
  <div :style="{ height: contentStyleObj }" >
    <qzf-tabs v-model:value="value" @tab-click="handleClick">
      <qzf-tabs-item label="存货管理" name="1" :cache="false">
        <inventoryCheck ref="inventoryCheck"></inventoryCheck>
        <div style="position: relative;">
          <div style="position: absolute;top: 10px;right: 13px;z-index: 999;"><qzf-video vid="63dd60f588d838171ba13eb766406761_6"></qzf-video> </div>
          <el-tabs type="border-card">
            <el-tab-pane label="库存汇总表">
              <inventoryPeriod ref="inventoryPeriod"></inventoryPeriod>
            </el-tab-pane>
            <el-tab-pane label="出入库明细" lazy>
              <inventoryDetail ref="inventoryDetail"></inventoryDetail>
            </el-tab-pane>
            <el-tab-pane label="暂估入库明细表" lazy>
              <inventoryEstimated ref="inventoryEstimated"></inventoryEstimated>
            </el-tab-pane>
            <el-tab-pane label="存货设置" lazy>
              <inventoryManage ref="inventoryManage"></inventoryManage>
            </el-tab-pane>
            <el-tab-pane label="存货初始化" lazy>
              <inventoryInit ref="inventoryInit"></inventoryInit>
            </el-tab-pane>
          </el-tabs>
        </div>
        
     
      </qzf-tabs-item>
      <!-- <qzf-tabs-item label="存货核算" name="5" :cache="false">
        <inventoryCheck ref="inventoryCheck"></inventoryCheck>
      </qzf-tabs-item> -->
      <qzf-tabs-item label="进销存明细" name="6" :cache="false">
        <saleStock ref="inventoryCheck"></saleStock>
      </qzf-tabs-item>
    </qzf-tabs>
  </div>
</template>

<script>
import inventoryDetail from "./components/inventoryDetail.vue"
import inventoryPeriod from "./components/inventoryPeriod.vue"
import inventoryManage from "./components/inventoryManage.vue"
import inventoryInit from "./components/inventoryInit.vue"
import inventoryEstimated from "./components/inventoryEstimated.vue"
import inventoryCheck from "./components/inventoryCheck.vue"
import saleStock from "./components/saleStock"
export default {
  name: "inventory",
  components:{
    inventoryDetail,
    inventoryPeriod,
    inventoryManage,
    inventoryEstimated,
    inventoryCheck,
    inventoryInit,
    saleStock
  },
  data () {
    return {
      value:'1',
      contentStyleObj: {}, //高度变化
    }
  },
  deactivated(){
    if(!this.$getCachedViews("inventory")){//销毁 mitt bus
      this.$bus.off("inventoryPeriodUpdate")
      this.$bus.off("inventoryEstimatedUpdate")
      this.$bus.off("inventoryManageUpdate")
      this.$bus.off("inventoryDetailUpdate")
    }
  },
  created(){
    this.contentStyleObj = this.$getHeight(190);
    this.initBus()
  },
  methods:{
    initBus(){
      this.$bus.on("inventoryPeriodUpdate", (val) => {
        if(this.$refs.inventoryPeriod){
          this.$refs.inventoryPeriod.getList()
        }
      });
      this.$bus.on("inventoryEstimatedUpdate", (val) => {
        if(this.$refs.inventoryEstimated){
          this.$refs.inventoryEstimated.getList()
        }
      });
      this.$bus.on("inventoryManageUpdate", (val) => {
        if(this.$refs.inventoryManage){
          this.$refs.inventoryManage.getList()
        }
      });
      this.$bus.on("inventoryDetailUpdate", (val) => {
        if(this.$refs.inventoryDetail){
          this.$refs.inventoryDetail.getList()
        }
      });
    }
  }
}
</script>

<style lang="scss">
.addForm{
  .el-select{
    margin-left: 0px;
    width: 100%;
  }
  .el-input{
    width: 72%;
  }
  
}

.inventory .el-tabs__content {
  min-height: 800px;
}
</style>