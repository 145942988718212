<template>
  <div style="position: relative;">
    <div style="float:left;">
      <search @success="getList" @cancel="cancel">
        <el-form style="margin:11px 0px 0px -26px;width:400px" label-width="120px" class="styleForm">
          <el-form-item label="存货名称 ：">          
            <el-input v-model.trim="listQuery.name" placeholder="请输入名称" style="width: 200px;" size="small" clearable/>
          </el-form-item>
          <el-form-item label="存货类型 ：">
            <selectInventoryType v-model:type="listQuery.type" style="width: 200px;"></selectInventoryType>
          </el-form-item>
          <el-form-item label="科目名称 ：">
            <subject-list subjectName="" v-model:subjectId="listQuery.subjectId" :codes="$findCode(code)" :addNotShow="true" size="small" :disabledAdd="true"></subject-list>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div style="text-align:right;float:right">
      <qzf-button size="small" type="primary" @success="add" :disabled="!$buttonStatus('ch_chsz_xz')">
        <el-icon><CirclePlus /></el-icon><span  >新增</span>
      </qzf-button>
      <qzf-button size="small" type="success" @success="daoru" :disabled="!$buttonStatus('ch_chsz_dr')">
        <el-icon><Folder /></el-icon><span  >导入</span>
      </qzf-button>
      <el-button type="primary" plain @click="daochu" size="small" icon="FolderOpened">导出</el-button>
      <qzf-button size="small" type="danger" @success="delAll" :disabled="!$buttonStatus('ch_chsz_sc')">
        <el-icon><Delete /></el-icon> <span  >批量删除</span>
      </qzf-button>
    </div>
    <div :style="{ height: this.contentStyleObj, paddingTop: '34px' }">
      <vxe-table
        ref="vxeTableInstance"
        v-loading="loading"
        :data="tableData"
        :scroll-y="{enabled: true, gt: 0,}"
        height="auto"
        style="width: 100%;"
        border
        stripe
        auto-resize
        size="mini"
        @sort-change="sortChange"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{resizable: true}"
      >
      <!-- <el-table stripe :data="list" style="width: 100%" border size="small" :height="contentStyleObj" v-loading="loading" @select="handleSelectionChange" ref="tableScrollManage" id="tableLazyLoadManage" @select-all="handleSelectionChangeAll" @sort-change="sortChange"> -->
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column field="sortColumn" align="center" type="checkbox" width="55" />
        <vxe-column title="编号" width="70" align="center">
        <template #default="scope">
          <span style="color: orange;">{{ sortNumber(scope.row.coding)}}</span>
        </template>
      </vxe-column>
        <vxe-column field="name" title="名称" min-width="160" />
        <vxe-column field="name2" title="别名" min-width="180" />
        <vxe-column align="center" field="unit" title="单位" min-width="80" />
        <vxe-column align="center" field="spec" title="型号" min-width="80" />
        <vxe-column align="center" field="type" title="类型" min-width="180">
          <template #default="scope">
            <span>{{ $inventoryTypeFilter(scope.row.type) }}</span>  
          </template>
        </vxe-column>
        <vxe-column align="center" field="subjectName" title="科目" min-width="240" />
        <vxe-column align="center" field="operation" title="操作" min-width="210">
          <template #default="scope">
            <dwhs :disabled="!$buttonStatus('ch_chsz_dwhs')" :row="scope.row"></dwhs>
            <qzf-button :disabled="!$buttonStatus('ch_chsz_sc')" size="small" type="text" @success="deleteInventory(scope.row.id)">删除</qzf-button>
            <qzf-button :disabled="!$buttonStatus('ch_chsz_bj')" size="small" type="text" @success="create(scope.row)">编辑</qzf-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
    <add-inventory ref="addInventory" @success="getList"></add-inventory>
    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="存货导入"></qzf-import-back>
  </div>
</template>

<script>
import { inventoryList , delInventory,inventoryManage} from '@/api/inventory'
import Search from '../../../../components/search/search.vue'
import selectInventoryType from "./selectInventoryType.vue"
import dwhs from "./dwhs.vue"
export default {
  name:'inventoryManage',
  components:{
    dwhs,
    Search,
    selectInventoryType
  },
  data() {
    return {
      code:['code1285','code1403','code1405','code1406','code1408','code1411','code1412','code1421','code1605','code1621'],
      list:[],
      listQuery:{
        name:'',
        type:undefined,
        page:1,
        limit:20
      },
      total:0,
      loading:false,
      selects:[],
      totalPage:0,
      pageSize:35,
      tableData:[],
      currentPage:1,
      allChecked:false,
    }
  },
  created() {
    this.getList()
    this.contentStyleObj= this.$getHeight(304)
  },
  methods:{
    getList(){
      this.loading = true
      inventoryList(this.listQuery).then(res=>{
        this.loading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
      })
    },
    deleteInventory(id){
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delInventory([{id:id}]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
            this.$store.dispatch("commons/getInventory")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getBusinessType")
          }
        })
      })
    },
    delAll(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1) 
        return
      }
      this.$confirm('确定批量删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delInventory(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
            this.$store.dispatch("commons/getInventory")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getBusinessType")
          }
        })
      })
    },
    add(){
      this.$refs.addInventory.init()
    },
    create(row){
      this.$refs.addInventory.init(row)
    },
    handleSelectionChange(data) {
      this.selects = data.records
    },
    handleSelectionChangeAll(data){
      this.selects = data.records
    },
    daoru(){
      this.$refs.importBck.handImport()
    },
    daochu(){
      let param = {
        query:this.listQuery,
        ids:[]
      };
      this.selects.map(v=>{
        param.ids.push(v.id)
      })
      inventoryManage(param).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery = {
        name:'',
        type:undefined,
        page:1,
        limit:originLimit
      }
      this.getList()
    },
    //编号
    sortNumber(e){
      if(!e){
        return "默认"
      }else{
        return e
      }
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.ordercoding = 1
      }else{
        this.listQuery.ordercoding = 0
      }
      this.getList()
    },
  }
}
</script>

<style>

</style>

<style scoped lang="scss">
 
.el-select{
  margin-left:0px
}
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 275px);
  border:1px solid #e6e6e6;
  position: relative;
}
</style>