<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="选择供应商对应科目（例如：暂估款）" v-model='dialogVisible' class="border_one button_bg dialog_box" >
    <el-form label-position="right" label-width="84px">
      <el-form-item label="会计科目：" class="item">
        <subject-list v-model:subjectId="subjectId" v-model:fzhsItemId="fzhsId"></subject-list>
      </el-form-item>
      <el-table height="400" size="small" style="width: 100%" border :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" :data="list">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column label="名称" align="center" min-width="150">
          <template #default="scope">
            {{scope.row.name}}
          </template>
        </el-table-column>
        <el-table-column label="冲销数量" align="center" min-width="130">
          <template #default="scope">
            <el-input size="small" style="width:100%" type="number" v-model="scope.row.backCount" @change="changeNum(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="冲销单价" align="center" min-width="130">
          <template #default="scope">
            <el-input size="small" style="width:100%" type="number" v-model="scope.row.price" @change="changeNum(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="冲销金额" align="center" min-width="130">
          <template #default="scope">
            <el-input size="small" style="width:100%" type="number" v-model="scope.row.backAmount"></el-input>
          </template>
        </el-table-column>
        
      </el-table>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="handleSure()" size="small">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { estimateHc } from '@/api/inventory'
export default {
  name:'inventoryWriteOff',
  data() {
    return {
      dialogVisible:false,
      zanguStatus: false,
      list:[],
      subjectId:0,
      fzhsId:0
    }
  },
  methods:{
    getList(item){
      this.dialogVisible = true
      this.list = item
      this.list.map(v=>{
        v.price = (Number(v.backAmount) / Number(v.backCount)).toFixed(2)
      })
    },
    handleSure(){
      this.list.map(v => {
        v.backAmount = v.backAmount*1
        v.backCount = v.backCount*1
      })
      let param = {
        subjectId:this.subjectId,
        fzhsId:this.fzhsId,
        items:this.list
      }
      estimateHc(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          this.dialogVisible = false
          this.$emit("success")
        }
      })
    },
    changeNum(row){
      if(row.price){
        row.backAmount = row.backCount ? (Number(row.price)*Number(row.backCount)).toFixed(2) : row.price
      }
    }
  }
}
</script>

<style>

</style>