<template>
  <div class="app-container" >
    <el-skeleton style="width: 240px" :loading="loading" animated>
      <template #default>
        <div style="display: flex;align-items: center;justify-content: space-between;">
          <el-card
          shadow="hover"
          :body-style="{ padding: '0px', marginBottom: '1px' }"
          v-for="item in lists"
          :key="item.name"
          class="card-style"
        >

          <div style="padding: 10px;position: relative;">

            <span style="font-size:13px;font-weight:500;margin-left:15px" class="methodStyle">{{ item.name }}</span>
            <div class="bottom card-header">
              <span :class="produceStatus == 0 ? 'spanItemqq':'spanItem'" v-if="item.name == '生产核算方法'">{{statusFilter(produceStatus)}}</span>
              <span :class="tradeStatus == 0 ? 'spanItemqq':'spanItem'" v-if="item.name == '贸易核算方法'">{{statusFilter(tradeStatus)}}</span>
              <span :class="processStatus == 0 ? 'spanItemqq':'spanItem'" v-if="item.name == '委托核算方法'">{{statusFilter(processStatus)}}</span>
              <span :class="projectStatus == 0 ? 'spanItemqq':'spanItem'" v-if="item.name == '工程核算方法'">{{statusFilter(projectStatus)}}</span>
              <span :class="fwStatus == 0 ? 'spanItemqq':'spanItem'" v-if="item.name == '服务核算方法'">{{statusFilter(fwStatus)}}</span>
              <qzf-button v-if="item.name == '生产核算方法'" @success="goProduce" class="buttonHs" plain size="small">
                <el-icon><Right /></el-icon><span  ></span>
              </qzf-button>
              <qzf-button v-if="item.name == '贸易核算方法'" @success="goTrade" class="buttonHs" plain size="small">
                <el-icon><Right /></el-icon><span  ></span>   
              </qzf-button>
              <qzf-button v-if="item.name == '委托核算方法'" @success="goProcess" class="buttonHs" plain size="small">
                <el-icon><Right /></el-icon><span  ></span>       
              </qzf-button>
              <qzf-button v-if="item.name == '工程核算方法'" @success="goProject" class="buttonHs" plain size="small">
                <el-icon><Right /></el-icon><span  ></span>          
              </qzf-button>
              <qzf-button v-if="item.name == '服务核算方法'" @success="goFw" class="buttonHs" plain size="small">
                <el-icon><Right /></el-icon><span  ></span>       
              </qzf-button>
            </div>
          </div>
        </el-card>
        </div>
      
      </template>
    </el-skeleton>
  </div>
</template>




<script>
import { inventoryCheckStatus } from '@/api/inventory'
import costSetting from "./costSetting.vue"
export default {
  components: {
    costSetting,
  },
  props: {

  },
  data () {
    return {
      lists: [],
      loading: true,
      fwStatus:0,         //服务
      processStatus:0,    //委托核算
      produceStatus:0,    //生产核算
      projectStatus:0,    //工程核算
      tradeStatus:0       //贸易核算
    }
  },
  mounted() {
    this.loading = false
    this.lists = [
      {
        name: '生产核算方法',
      },
      {
        name: '贸易核算方法',
      },
      {
        name: '委托核算方法',
      },
      {
        name: '工程核算方法',
      },
      {
        name: '服务核算方法',
      },
    ]
  },
  created() {
    this.init()
    this.$bus.on("inventoryCheckUpdate", (val) => {
      this.init()
    });
  },
  methods: {
    goProduce(){
      this.$router.push({
        path: "/input/produceConvert",
        name: "produceConvert"
      });
    },
    goTrade(){
      this.$router.push({
        path: "/input/tradeConvert",
        name: "tradeConvert"
      });
    },
    goProcess(){
      this.$router.push({
        path: "/input/processConvert",
        name: "processConvert"
      });
    },
    goProject(){
      this.$router.push({
        path: "/input/projectConvert",
        name: "projectConvert"
      });
    },
    goFw(){
      this.$router.push({
        path: "/input/fwConvert",
        name: "fwConvert"
      });
    },
    init(){
      inventoryCheckStatus({}).then(res=>{
        //console.log(res.data.data);
        this.processStatus = res.data.data.process
        this.produceStatus = res.data.data.produce
        this.projectStatus = res.data.data.project
        this.tradeStatus = res.data.data.trade
        this.fwStatus = res.data.data.fw
      })
    },
    statusFilter(status){
      if(status == 0){
        return "未开始"
      }else if(status >= 1){
        return "已完成"+status+'次'
      }
    },
    
  }
}
</script>
<style scoped lang="scss">
ul {
  li {
    width: calc(100% - 20px);
    height: 80px;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
  }
}
.bottom{
  .spanItem{
    border-radius: 15px;
    background-color: var(--themeColor,#17a2b8);
    color: white;
    font-size: 11px;
    font-weight: 400;
    padding: 3px 6px;
    margin-left: 15px;
    position: absolute;
    top: 35px;
  }
  .spanItemqq{
    border-radius: 15px;
    background-color: #c4c3c3;
    color: white;
    font-size: 11px;
    font-weight: 400;
    padding: 3px 7px;
    margin-left: 15px;
    position: absolute;
    top: 35px;
  }
}
.buttonHs{
  position: absolute;
  border-radius: 3px !important;
  top: 24px;
  right: 7px;
}

.app-container{
  padding: 11px 16px;
  width: 100%;
  min-height: 90px;
  border: 1px solid #e6e6e6;
  border-bottom: none;
}
.card-style{
  width:19%;
  height:70px;
  background-color: #d8ecff;
  // float: left;
  // margin-left: 1%;
}
.methodStyle{
  color: #333;
}

</style>