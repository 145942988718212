<template>
  <div style="position: relative;">
    <div style="float:left;">
      <search @success="getList" @cancel="cancel">
        <el-form style="margin:11px 0px 0px -26px;width:400px" label-width="120px" class="styleForm">
          <el-form-item label="存货名称 ：">          
            <el-input v-model="listQuery.name" placeholder="请输入名称或编号" style="width: 200px;" size="small" clearable/>
          </el-form-item>
          <el-form-item label="存货类型 ：">
            <selectInventoryType v-model:type="listQuery.type" style="width: 200px;margin-left: 0;"></selectInventoryType>
          </el-form-item>
          <el-form-item label="出入库类型 ："> 
            <el-select v-model="listQuery.detailType" placeholder="请选择来源" size="small" multiple collapse-tags style="width: 200px;margin-left: 0;">
              <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择账期 ：" :label-width="formLabelWidth">
            <qzf-period v-model:period="listQuery.beginTime" style="width:93px;margin-left: 0;" :hidden="true"></qzf-period>
            <span>-</span>
            <qzf-period v-model:period="listQuery.endTime" style="width:93px" :hidden="true"></qzf-period>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div style="width:20%;text-align:right;float:right">
      <el-dropdown size="small" style="margin-left:10px">
        <el-button size="small" type="primary">
          更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="b">
              <span @click="exportInventory">导出</span>
            </el-dropdown-item>
            <el-dropdown-item command="c">
              <span @click="printDetail">打印</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div :style="{ height: this.contentStyleObj, paddingTop: '34px' }">
      <vxe-table
        ref="vxeTableInstance"
        v-loading="loading"
        :data="tableData"
        :scroll-y="{enabled: true, gt: 0,}"
        height="auto"
        style="width: 100%;"
        border
        stripe
        auto-resize
        size="mini"
        :show-footer="!!tableData.length"
        :footer-method="getFooterDataTable"
        @sort-change="sortChange"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{resizable: true}"
      >
      <!-- <el-table stripe :data="list" style="width: 100%" border size="small" :height="contentStyleObj" @select="handleSelectionChange" show-summary :summary-method="getSummaries" ref="tableScrollDetail" id="tableLazyLoadDetail" @select-all="handleSelectionChangeAll"> -->
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column align="center" field="sortColumn" type="checkbox" width="50"> </vxe-column>
        <vxe-column field="inventoryName" title="名称" width="180"> 
          <template #default="scope">
            <span>{{scope.row.inventoryName}}</span>
          </template>
        </vxe-column>
        <vxe-column field="inventoryName2" title="别名" min-width="180" />
        <vxe-column field="spec" align="center" title="型号" min-width="100" />
        <vxe-column field="time" align="center" prop="time" title="日期" min-width="100">
          <template #default="scope">
            <span>{{$parseTime(scope.row.time, "{y}-{m}-{d}")}}</span>
          </template>
        </vxe-column>
        <vxe-column field="type" align="center"  title="出入库类型" min-width="180" show-overflow-tooltip>
          <template #default="scope">
            <span>{{$inventoryCategoryFilter(scope.row.type)}}</span>
          </template>
        </vxe-column>
        <vxe-column field="inventoryType" align="center"  title="存货类型" min-width="180">
          <template #default="scope">
            <span>{{$inventoryTypeFilter(scope.row.inventoryType)}}</span>
          </template>
        </vxe-column>
        <vxe-column field="unit" align="center" title="单位" min-width="80" />
        <vxe-column field="voucherNo" align="center" title="关联凭证" min-width="80"> 
          <template #default="scope">
            <span @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
          </template>
        </vxe-column>
        <vxe-column align="center" field="count" title="数量" min-width="130" />
        <vxe-column align="center" field="amount" title="金额" min-width="150" />
        <vxe-column align="center" field="operation" title="操作" width="100">
          <template #default="scope">
            <qzf-button v-if="scope.row.type == 11 || scope.row.type == 10 || scope.row.type == 1" @success="delInventory(scope.row)" :disabled="!$buttonStatus('ch_crkmx_cx')">撤销</qzf-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>

    <printVouch ref="printVouch" name="inventoryDetail"  :listQuery1="this.listQuery"></printVouch>

  </div>
</template>

<script>
import { inventoryDetailList , delInventoryDetail} from '@/api/inventory'
import selectInventoryType from "./selectInventoryType.vue"
import printVouch from "../../../bookkeeps/components/printVouch.vue"
import { inOutInventorySummarySheet } from "@/api/export"


export default {
  name:'inventoryDetail',
  components:{
    selectInventoryType,
    printVouch
  },
  data() {
    return {
      listQuery:{
        limit:20,
        page:1,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        detailType:[]
      },
      total:0,
      list:[],
      footerDataTable: [],
      fromOptions:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'初始化导入'
        },
        {
          value:2,
          label:'进项入库-进项发票录入'
        },
        {
          value:3,
          label:'暂估入库'
        },
        {
          value:4,
          label:'生产商品入库(生产结转)'
        },
        {
          value:6,
          label:'领料出库(生产结转)'
        },
        {
          value:7,
          label:'商品结转出库(贸易结转)'
        },
        {
          value:8,
          label:'抵消销项卖出(特殊)'
        },
        {
          value:9,
          label:'销项卖出-销项发票录入'
        },
        {
          value:10,
          label:'手工出库'
        },
        {
          value:11,
          label:'手工入库'
        },
        {
          value:12,
          label:'凭证关联出库'
        },
        {
          value:13,
          label:'凭证关联入库'
        },
        {
          value:31,
          label:'暂估回冲'
        },
        // {
        //   value:91,
        //   label:'销项回冲'
        // }
      ],
      comId: this.$store.getters['user/comInfo'].comId*1,
      ids:[],
      totalPage:0,
      pageSize:35,
      tableData:[],
      currentPage:1,
      allChecked:false,
      sels:[],
    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(304)
  },
  methods:{
    getFooterDataTable({ columns, data }) {
      const sumKey = ['amount', 'count',]
      const footerData = columns.map((item, i) => {
        if (i === 0) {
          return '合计'
        }
        if (sumKey.includes(item.field)) {
          return data.reduce((acc, d) => {
            acc = +((acc + (d[item.field] || 0)).toFixed(3))
            return acc
          }, 0)
        }
        return ''
      })
      return [footerData]
    },
    getList() {
      inventoryDetailList(this.listQuery).then(res=>{
        this.tableData = res.data.data.list || []
        this.total = res.data.data.total
      })
    },
    delInventory(row){
      delInventoryDetail([row]).then(res=>{
        if(res.data.msg == "success"){
          this.getList()
        }
      })
    },
    getIds(){
      this.ids = this.sels.map(item=>{
        return item.id
      })
    },
    handleSelectionChange(data) {
      this.sels = data.records
      this.getIds()
    },
    handleSelectionChangeAll(data){
      this.sels = data.records
      this.getIds()
    },
    printDetail(){
      this.$refs.printVouch.init([this.comId],this.listQuery.period,this.ids)
    },
    exportInventory(){
      let param = {
        bookNames:['inventoryPeriod'],
        type:'pdf',
        query:this.listQuery,
        ids:this.ids
      }
      inOutInventorySummarySheet(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    xx(period, list){
      let s = ""
      for (let index = 0; index < list.length; index++) {
        let element = list[index];
        if(element.label != '初始账期'){
          for (let index = 0; index < element.options.length; index++) {
            let element2 = element.options[index];
            if(element2.period == period){
              s = element.label
            }
          }
        } 
      }
      return s
    },
    gotoSubjectItem(row){
      let voucherId = row.voucherId
      let ops = this.$store.getters['commons/periods'];
      let lll = this.xx(row.period,ops)
      this.$store.dispatch('commons/getSubjects', this.comId)
      if(lll != "正常账期"){
        this.$store.dispatch('commons/setParam', {historyVoucherId: voucherId,hisVoucherPeriod: row.period})
        this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
        this.$router.push({
          path: "/bookkeeps/components/historyVoucher",
          name: "historyVoucher",
        });
      }else{
        this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
        this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
        this.$router.push({
          path: "/bookkeeps/addVoucher",
          name: "addVoucher"
        });
      }
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        limit:originLimit,
        page:1,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        detailType:[]
      }
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
 
.app-containers {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  min-height: calc(100vh - 275px);
  border:1px solid #e6e6e6;
  position: relative;
}
</style>