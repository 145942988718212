<template>
  <el-select @change="changeType" filterable v-model="type" placeholder="选择类别" size="small" style="width:130px">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
export default {
  name:'selectInventoryType',
  props:{
    type: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      options:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'原材料'
        },
        {
          value:2,
          label:'库存商品'
        },
        {
          value:3,
          label:'半成品'
        },
        {
          value:4,
          label:'委托加工物资'
        },
        {
          value:5,
          label:'周转材料'
        },
        {
          value:6,
          label:'包装物'
        },
        {
          value:7,
          label:'消耗性生物资产'
        },
        {
          value:20,
          label:'低值易耗品'
        },
        {
          value:21,
          label:'材料采购'
        },
        {
          value:22,
          label:'生产性生物资产'
        },
        {
          value:8,
          label:'工程物资'
        },
        {
          value:9,
          label:'开发产品'
        },
        {
          value:10,
          label:'发出产品'
        },
        {
          value:11,
          label:'其他'
        },
      ],
    }
  },
  methods: {
    changeType(){
      this.$emit("update:type", this.type)
      this.$emit("success", this.type)
    }
  }
}
</script>