<template>
  <div class="app-container">
    <div class="top_btns clearfix">
      <div class="left_box">
        <el-row>
          <el-col :span="5">
            <span style="width:60px;display:inline-block">账期筛选:</span>
            <qzf-period v-model:period="listQuery1.beginTime" class="qzfPeriod"></qzf-period>
              <span style="width:4px;display:inline-block">-</span>
            <qzf-period v-model:period="listQuery1.endTime" class="qzfPeriod"></qzf-period>
          </el-col>
          <el-col :span="19">
          </el-col>
          <!-- 悬浮导航 -->
          <h1 v-if="title" style="font-size: 16px;margin-bottom: 6px;display: inline-block;">
            {{title}}
            <el-button @click="getList2()" size="small">刷新</el-button>  
          </h1>
        </el-row>

      </div>
    </div>
    <el-row>
      <el-col :span="5">
        <div class="searchGjz">
          <el-input v-model="listQuery.name" placeholder="请输入存货名称" style="width: 69%;margin-right: 1%;" size="small" @change="getList" clearable/>
          <selectInventoryType v-model:type="listQuery.type" @success="getList" style="width:30% !important"></selectInventoryType>
          <div class="elTree" :style="{height:contentStyleObj}" style="boxShadow: rgb(219 211 211 / 58%) 2px 2px 7px 3px">
            <p style="font-size: 13px;color:var(--themeColor,#17a2b8);padding: 5px 24px;">存货名称</p>
            <el-tree ref="tree"  :filter-node-method="filterNode" :data="data" :props="defaultProps" @node-click="handleNodeClick" @check="handleCheckChangeArea" node-key="id" >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span style="display: flex;align-items: center;"><el-icon style="margin-right: 4px;"><folder /></el-icon> {{ node.label }}</span>
                </span>
              </template>
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="19">
        <div>
          <div style="float:right;margin-bottom: 10px;">
            <el-button type="success" size="small" @click="printExport('pdf')"> <el-icon><Printer /></el-icon> <span>打印</span></el-button>
            <el-button type="primary" size="small" @click="printExport('excel')"><el-icon><FolderOpened /></el-icon> <span>导出</span></el-button>
          </div>
          <el-table stripe :height="contentStyleObj" size="small" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 98%;margin-left: 25px;">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column label="存货类型" align="center" min-width="90">
              <template #default="scope">
                {{$inventoryTypeFilter(scope.row.types)}}
              </template>
            </el-table-column>
            <el-table-column label="名称" align="center" min-width="90">
              <template #default="scope">
                <el-tooltip v-if="scope.row.nowName" class="item" effect="dark" :content="scope.row.nowName" placement="top-start">
                  <span>{{ scope.row.nowName }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="单位" align="center" min-width="60">
              <template #default="scope">
                <span>{{scope.row.unit}}</span>
              </template>
            </el-table-column>
            <el-table-column label="账期" align="center" min-width="90">
              <template #default="scope">
                <span>{{scope.row.period}}</span>
              </template>
            </el-table-column>

            <el-table-column label="业务类型" align="center" min-width="90">
              <template #default="scope">
                <!-- <span style="font-weight: bold;" v-if="scope.row.summary == '期初结存' || scope.row.summary == '本期合计' || scope.row.summary == '本年累计'">{{scope.row.summary}}</span> -->
                <span>{{scope.row.summary}}</span>
              </template>
            </el-table-column>
            <el-table-column label="关联凭证" align="center" min-width="90">
              <template #default="scope">
                <span v-if="scope.row.voucherId" @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
              </template>
            </el-table-column>
            <el-table-column label="入库" align="center">
              <el-table-column label="数量" width="90">
                <template #default="scope">
                  <span v-if="scope.row.inCount">{{ scope.row.inCount }}</span>
                </template>
              </el-table-column>
              <el-table-column label="单价" width="90">
                <template #default="scope">
                  <span v-if="scope.row.inPrice">{{ scope.row.inPrice }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="inAmount" label="金额" width="110"></el-table-column>
            </el-table-column>
            <el-table-column label="出库" align="center">
              <el-table-column prop="outCount" label="数量" width="90"></el-table-column>
              <el-table-column prop="outPrice" label="单价" width="90"></el-table-column>
              <el-table-column prop="outAmount" label="金额" width="110"></el-table-column>
            </el-table-column>
            <el-table-column label="期末结存" align="center">
              <el-table-column prop="endCount" label="数量" width="90"></el-table-column>
              <el-table-column prop="endPrice" label="单价" width="90"></el-table-column>
              <el-table-column prop="endAmount" label="金额" width="110"></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- <printVouch ref="printVouch" :name="this.name1" :listQuery1="this.listQuery" :DY="this.DY"></printVouch>
    <exportFile ref="exportFile" name="book_detail_account" :listQuery1="this.listQuery"></exportFile> -->
  </div>
</template>

<script>
import { inventoryList,getDetailAccountInverntoryList } from '@/api/inventory'
// import { bookDetailAccountList } from "@/api/book.js"
import { detailAccountInventoryOut } from '@/api/printSet'
import selectInventoryType from "./selectInventoryType.vue"
// import printVouch from "./printVouch.vue"
// import exportFile from "./exportFile.vue"
export default {
  name:'saleStock',
  components: { 
    // printVouch,exportFile
    selectInventoryType
    },
  data() {
    return {
      name1:'',
      DY: 0,
      contentStyleObj:{}, //高度变化
      formLabelWidth: '120px',
      filterText: '',
      // optionType: '1',
      listLoading: false,
      data:[],
      defaultProps: {
        children: 'children',
        label: 'name2',
      },
      list: [],
      listQuery: {
        name: '',
        type: 0
      },

      listQuery1: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        beginTime: '',
        endTime: '',
        id:undefined
      }
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(270)
    this.getList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    getList() {
      inventoryList(this.listQuery).then(res=>{
        this.data = res.data.data.list
        this.loading = false
      })
    },
    daochu(){
      this.$refs.exportFile.init()
    },
    printAll(e) {
      this.DY = e
      this.name1 = 'book_detail_account',
      this.$nextTick(()=>{
      this.$refs.printVouch.init()
      })
      
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name2.indexOf(value) !== -1
    },
    handleNodeClick(item) {
      let type = item.type
      this.listQuery1.id = Number(item.id)
      // this.listQuery.fzhsId = Number(item.fzhsItemId)
      this.listLoading = true
      getDetailAccountInverntoryList(this.listQuery1).then(res => {
        if(res.data.data.list) {
          this.list = res.data.data.list[0]
          this.list.forEach(element => {
            element.types = type
          });
          console.log(this.list);
          this.listLoading = false
        }else {
          this.list = []
          this.listLoading = false
        }
      })
    },
    // 打印导出
    printExport(e){
      let param = {
        ids:this.listQuery1.id?[this.listQuery1.id]:[],
        type:e,
        startPeriod:this.listQuery1.beginTime,
        endPeriod:this.listQuery1.endTime
      }
      detailAccountInventoryOut(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    //凭证跳转

    xx(period, list){
      let s = ""
      for (let index = 0; index < list.length; index++) {
        let element = list[index];
        if(element.label != '初始账期'){
          for (let index = 0; index < element.options.length; index++) {
            let element2 = element.options[index];
            if(element2.period == period){
              s = element.label
            }
          }
        } 
      }
      return s
    },
    gotoSubjectItem(row){
      let voucherId = row.voucherId
      let ops = this.$store.getters['commons/periods'];
      let lll = this.xx(row.period,ops)
      this.$store.dispatch('commons/getSubjects', this.listQuery1.comId)
      if(lll != "正常账期"){
        this.$store.dispatch('commons/setParam', {historyVoucherId: voucherId,hisVoucherPeriod: row.period})
        this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
        this.$router.push({
          path: "/bookkeeps/components/historyVoucher",
          name: "historyVoucher",
        });
      }else{
        this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
        this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
        this.$router.push({
          path: "/bookkeeps/addVoucher",
          name: "addVoucher"
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.searchBtn {
  text-align: center;
  button {
    margin-right: 5px;
  }
}
.top_btns {
  margin-bottom: 20px;
  .left_box{
    float: left;
    width: 100%;
  }
  // .right_box {
  //   float: right;
  //   width: 30%;
  //   text-align: right;
  // }
}
.el-select {
  margin: 0 !important;
  width: 120px !important;
}
.elTree {
    width: 100%;
    height: 500px;
    overflow: scroll;
    // box-shadow: rgb(219 211 211 / 58%) 5px 8px 8px 3px;
    border: 1px solid #ede5e5;
    margin-top: 10px;
}
::-webkit-scrollbar {
    width: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(17, 16, 16, 0.13);
    -webkit-box-shadow: rgba(0, 0, 0, 0.9);
    box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #efefef;
}
.qzfPeriod{
  width: calc(50% - 32px) !important;
}
</style>